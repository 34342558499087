<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back to="/reports" />
        </v-col>
      </v-row>

      <reports-fields
        v-model="report"
        :loading-get="loadingGet"
        :loading-save="loading"
        :form-validation="formValidation"
        @save="save"
      />
    </v-container>
  </div>
</template>

<script>
  import reportsApi from '@/api/reports'
  import dayjs from 'dayjs'

  export default {

    components: {
      ReportsFields: () => import('./ReportsFields'),
    },

    data () {
      return {
        report: {},
        loading: false,
        loadingGet: false,
        formValidation: {},
      }
    },

    mounted () {
      this.get()
    },

    methods: {
      async get () {
        try {
          this.loadingGet = true
          const response = await reportsApi.get(this.$route.params.id)

          const report = {
            id: response.data.report.id,
            date: dayjs(response.data.report.date).format('DD/MM/YYYY'),
            customerId: response.data.report.customerContact.customerId,
            customerContactId: response.data.report.customerContactId,
            serviceModalityId: response.data.report.serviceModalityId,
            details: response.data.report.details,
            observations: response.data.report.observations || null,
            technicians: [],
            sectors: [],
            workShifts: [],
            checklistItems: [],
          }

          for (const key in response.data.report.technicians) {
            report.technicians.push(response.data.report.technicians[key].userId)
          }

          for (const key in response.data.report.sectors) {
            report.sectors.push(response.data.report.sectors[key].sectorId)
          }

          for (const key in response.data.report.checklistItems) {
            report.checklistItems.push(response.data.report.checklistItems[key].checklistItemId)
          }

          for (const key in response.data.report.workShifts) {
            const workShift = {
              shift: response.data.report.workShifts[key].shift,
              start: response.data.report.workShifts[key].start?.substring(0, 5),
              end: response.data.report.workShifts[key].end?.substring(0, 5),
            }
            report.workShifts.push(workShift)
          }

          this.report = report
        } catch (e) {
          console.log(e)
          this.$router.push('/reports', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      async save () {
        this.loading = true
        this.formValidation = {}

        try {
          await reportsApi.update(this.report.id, this.report)
          this.$router.push(
            '/reports',
            () => this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') }),
          )
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)

            e.response.data.fields.forEach(field => {
              if (field.param.includes('workShifts')) {
                this.formValidation.workShifts = [field.msg]
              }
            })

            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
